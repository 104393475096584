<template>
    <div>{{time}}</div>
</template>

<script>
export default {
    data() {
        return {
			time:''
        }
    },
    created() {
		this.get_time();
		this.timer = setInterval(() => {
			this.get_time()
		}, 1000);
	},
	destroyed(){
		clearTimeout(this.timer)
	},
	methods:{
		get_time(){
			this.time = this.$moment().format('YYYY年MM月DD日 dddd HH:mm:ss')
		}
	}
}
</script>