<template>
    <div class="bi_body">
        <header class="head">
			<div class="tip">润田，滴滴润心田！</div>
			<div class="time" ><Time /></div>
		</header>
        <div class="bi_content">
            <div class="left_data">
                <div class="goods">
                    <div class="echarts" id="goods"></div>
                </div>
                <div class="region">
					<div class="class_type">
						<div class="dd">
							<div class="line_icon"></div>
							<div class="name">销售额(千元)</div>
						</div>
						<div class="dd">
							<div class="line_icon line_icon_two"></div>
							<div class="name">销量(件)</div>
						</div>
					</div>
                    <div class="echarts" id="region"></div>
                </div>
                <div class="real_time_visit">
					<div class="ul">
						
						<div class="li" v-for="(item,index) in real.visit_list" :key="index">
							<span class="time">{{item.update_time}}</span>
							<div class="cont">
								<div class="name">{{item.username}}</div>
								<div class="area" >{{item.department}}</div>
								<!-- ·  -->
								<div class="type">{{item.type}}</div>
								<div class="customer">{{item.customer}}</div>
							</div>
						</div>

					</div>
				</div>
            </div>
			<!-- real:{
				attendance:{
					static_data:[],	
				},
				head:{},
				visit_list:[],
			} -->
            <div class="center_data">
                <div class="center_top">
                    <div class="dd">
                        <!-- <img class="img" src="../../assets/bi/img/visit_num.png" alt /> -->
                        <div class="cent">
                            <div class="desc">
                                <div class="label">较昨天</div>
                                <img v-if="real.head.visit_diff >=0" class="icon" src="../../assets/bi/img/icon_up.png" alt="">
                                <img v-else class="icon" src="../../assets/bi/img/icon_down.png" alt />
                                <div class="number" :class="[real.head.visit_diff >=0 ? 'up' : '']">{{real.head.visit_diff}}</div>
                            </div>
                            <div class="num">{{real.head.visit_num}}</div>
                            <div class="info">今日拜访次数</div>
                        </div>
                    </div>
                    <div class="dd">
                        <!-- <img class="img" src="../../assets/bi/img/terminal_number.png" alt /> -->
                        <div class="cent">
                            <div class="desc">
                                <div class="label">较昨天</div>
                                <img v-if="real.head.customer_diff >=0" class="icon" src="../../assets/bi/img/icon_up.png" alt="">
                                <img v-else class="icon" src="../../assets/bi/img/icon_down.png" alt />
                                <div class="number" :class="[real.head.customer_diff >=0 ? 'up' : '']">{{real.head.customer_diff}}</div>
                            </div>
                            <div class="num">{{real.head.customer_num}}</div>
                            <div class="info">终端数</div>
                        </div>
                    </div>
                    <div class="dd">
                        <!-- <img class="img" src="../../assets/bi/img/sale_num.png" alt /> -->
                        <div class="cent">
                            <div class="desc">
                                <div class="label">较昨天</div>
                                <img v-if="real.head.order_diff >=0" class="icon" src="../../assets/bi/img/icon_up.png" alt="">
                                <img v-else class="icon" src="../../assets/bi/img/icon_down.png" alt />
                                <div class="number" :class="[real.head.order_diff >=0 ? 'up' : '']">{{real.head.order_diff}}</div>
                            </div>
                            <div class="num">{{real.head.order_total}}</div>
                            <div class="info">今日销售金额(元)</div>
                        </div>
                    </div>
					<div class="dd">
                        <!-- <img class="img" src="../../assets/bi/img/sale_num.png" alt /> -->
                        <div class="cent">
                            <div class="desc">
                                <div class="label">昨日新增</div>
								<img class="icon" src="../../assets/bi/img/icon_up.png" alt="">
								<div class="number up" >{{real.head.order_num_diff}}</div>
                            </div>
                            <div class="num">{{real.head.order_num}}</div>
                            <div class="info">累计订单量</div>
                        </div>
                    </div>
					<div class="dd">
                        <!-- <img class="img" src="../../assets/bi/img/sale_num.png" alt /> -->
                        <div class="cent">
                            <div class="desc" style="">
                                <div class="label">昨日新增</div>
								<img class="icon" src="../../assets/bi/img/icon_up.png" alt="">
								<div class="number up" >{{real.head.order_price_diff}}</div>
                            </div>
                            <div class="num" >{{real.head.order_price}}</div>
                            <div class="info">累计销售金额(元)</div>
                        </div>
                    </div>
                </div>
                <div class="map_par">
                    <div class="map">
						<div class="left"></div>
						<div class="right"></div>
						<div class="top"></div>
						<div class="bottom"></div>
						<baidu-map class="baidu_map" @ready="readyMap" :scroll-wheel-zoom="true" :center="center" :mapClick="false" :zoom="13">
						 </baidu-map>
					</div>
					<div class="visit_num" >
						<div class="visit_num_head">
							<div class="ind_h2">终端拜访次数</div>
							<div class="visit_tab">
								<div class="class_type">
									<div class="dd">
										<div class="line_icon line_icon_two"></div>
										<div class="name">拜访次数</div>
									</div>
								</div>

								<div class="tab">
									<div class="dd " @click="visit_click(item)" :class="[item.key == visit_num_index? 'on' : '']" v-for="item in date_list" :key="item.key">{{item.value}}</div>
								</div>
							</div>
						</div>
						<div class="echarts" id="visit_num"></div>
					</div>
                </div>
            </div>
            <div class="right_data">
                <div class="team">
					<div class="attendance">
						<div class="attend">
							<div class="situation">
								<div class="title">今日考勤情况</div>
								<div class="atte_dl">
									<div class="icon"><img src="../../assets/bi/img/icon_male.png" alt=""></div>
									<div class="dl">
										<div class="dd" :style="{width:real.attendance.ratio + '%'}">
										</div>
										<div class="tip" :style="{left:real.attendance.ratio + '%'}">{{real.attendance.ratio}}%</div>
										<!-- <div class="dd flex"></div> -->
									</div>
									<!-- <div class="icon"><img src="../../assets/bi/img/icon_female.png" alt=""></div> -->
								</div>
								<div class="atte_dl_num">
									<div class="dd">出勤<span class="number">{{real.attendance.attendance_num}}</span>人</div>
									<div class="dd flex">应出勤<span class="number">{{real.attendance.team_num}}</span>人</div>
								</div>
							</div>
							<div class="statistics">
								<div class="dl">
									<dd v-for="item in real.attendance.static_data" :key="item.key"><span class="label">{{item.name}}</span><span class="number">{{item.value}}<span class="company">{{item.unit}}</span></span></dd>
								</div>
							</div>
						</div>
						<div class="on_line">
							<div class="line_tit">近30天在线人数</div>
							<div class="class_type">
								<div class="dd">
									<div class="line_icon"></div>
									<div class="name">在线人数</div>
								</div>
								<div class="dd">
									<div class="line_icon line_icon_two"></div>
									<div class="name">出勤人数</div>
								</div>
							</div>
						</div>
					</div>
					<div class="echarts" id="team"></div>
				</div>
                <div class="terminal_num">
					<div class="echarts" id="terminal_num"></div>
				</div>
                <div class="pushi">
					<div class="pushi_tab">
						<div class="tab">
							<div class="dd " @click="terminal_click(item)" :class="[item.key == terminal_num_index ? 'on' : '']" v-for="item in date_list" :key="item.key">{{item.value}}</div>
						</div>
					</div>
					<div class="echarts" id="pushi"></div>
				</div>
            </div>
        </div>
        <footer class="footer"></footer>
    </div>
</template>


<script>
import { BiIndex,location,realtime,visitChart,visitCoverChart } from '@/api/bi'
import echarts from 'echarts'
import method from '@/methods/method'
import Time from '@/components/Time'
// import mapv from 'mapv'

let colors = {
    c: '#1395FC',
    label_color: '#fff',
    line: '#fff',
    bar_bj: '#022C5E',
    bar: new echarts.graphic.LinearGradient(
        0, 0, 0, 1,
        [
            { offset: 0, color: '#00FBCD' },
            { offset: 1, color: '#2A82CF' }
        ]
    )
}

let opacitys = {
    line: .1
};

function echarts_init () {

    var option = {
        title: "",
        tooltip: {},
        legend: {
            show: false,
        },
        grid: {
            left: 60,
            top: 40,
            right: 30,
            bottom: 40,
            tooltip: {
                show: false,
            }
        },
        xAxis: {
			data: [],
			
            axisLabel: {
				color: colors.c,
				
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false,
                lineStyle: {
                    color: colors.line,
                    opacity: opacitys.line,
                }
            },
        },
        yAxis: {
            axisLabel: {
                color: colors.c
            },
            axisLine: {
                show: false
            },
            splitLine: {
                lineStyle: {
                    color: colors.line,
                    opacity: opacitys.line,
                }
            }
        },
        series: [{
            name: '',
            type: 'bar',
            data: [],
            itemStyle: {
                color: colors.bar,
                barBorderRadius: [7, 7, 0, 0]
            },
            showBackground: true,
            backgroundStyle: {
                color: colors.bar_bj,
                barBorderRadius: [7, 7, 0, 0]
            },
            label: {
                show: true,
                color: colors.label_color,
                position: 'top',
            },
            barWidth: 14
        }]
    };

    // 商品销售额
    let goods_option = {
		...JSON.parse(JSON.stringify(option)),
		grid: {
			...JSON.parse(JSON.stringify(option.grid)),
			bottom:80,
        },
		xAxis: {
			...JSON.parse(JSON.stringify(option.xAxis)),
			axisLabel:{
				...JSON.parse(JSON.stringify(option.xAxis.axisLabel)),
				rotate:-45,
				interval:0,
			}
        },
		yAxis: {
			...JSON.parse(JSON.stringify(option.yAxis)),
			axisLabel:{
				...JSON.parse(JSON.stringify(option.yAxis.axisLabel)),

				formatter:function(value){
					return method.format(value)+'k'
				}
			}
        },
    }
    var goods_chart = echarts.init(document.getElementById('goods'));
    goods_chart.setOption(goods_option);


    // 折线图默认样式
    let line_style = {
        type: 'line',
        smooth: true,
        itemStyle: {
            color: '#588FFF'
		},
		label: {
			show: false,
			color: colors.label_color,
			position: 'top',
		},
        areaStyle: {
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#588FFF'
                }, {
                    offset: 1, color: 'rgba(145,194,255,.5)'
                }],
                global: false
            }
        },
    };

    // 折线图样式2
    let line_style_two = {
        ...JSON.parse(JSON.stringify(line_style)),
        itemStyle: {
            ...JSON.parse(JSON.stringify(line_style.itemStyle)),
            color: "#20E199"
		},
		areaStyle: {
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#20E199'
                }, {
                    offset: 1, color: 'rgba(67,242,201,.5)'
                }],
                global: false
            }
        },
    };

    // 近30天区域销售情况
    let region_option = {
		...JSON.parse(JSON.stringify(option)),
        xAxis: {
            ...JSON.parse(JSON.stringify(option.xAxis)),
            boundaryGap: false,
		},
		yAxis: [
			{
				...JSON.parse(JSON.stringify(option.yAxis)),
				axisLabel:{
					...JSON.parse(JSON.stringify(option.yAxis.axisLabel)),
					formatter:function(value){
						return method.format(value)+'k'
					}
				}
			},
			{
				...JSON.parse(JSON.stringify(option.yAxis)),
			}
		],
		grid: {
			...JSON.parse(JSON.stringify(option.grid)),
			right:60,
        },
        series: [
            {
                ...JSON.parse(JSON.stringify(line_style_two)),
                name: '',
                data: []
			},
			{
                ...JSON.parse(JSON.stringify(line_style)),
				name: '',
				yAxisIndex:1,
                data: []
            },
        ]
    }
    var region_chart = echarts.init(document.getElementById('region'));
	region_chart.setOption(region_option);
	

	// 终端拜访次数
    let visit_num_option = {
		...JSON.parse(JSON.stringify(option)),
		

		grid: {
			...JSON.parse(JSON.stringify(option.grid)),
            top: 15,
		},
		
        xAxis: {
            ...JSON.parse(JSON.stringify(option.xAxis)),
            boundaryGap: false,
        },
        series: [
            {
                ...JSON.parse(JSON.stringify(line_style_two)),
                name: '',
                data: []
			},
        ]
    }
    var visit_num_chart = echarts.init(document.getElementById('visit_num'));
	visit_num_chart.setOption(visit_num_option);


	// 近30天在线人数
    let team_option = {
        ...JSON.parse(JSON.stringify(option)),
        xAxis: {
            ...JSON.parse(JSON.stringify(option.xAxis)),
            boundaryGap: false,
		},
		grid: {
			...JSON.parse(JSON.stringify(option.grid)),
            top: 15,
		},
        series: [
			{
                ...JSON.parse(JSON.stringify(line_style)),
                name: '',
                data: []
			},
			{
                ...JSON.parse(JSON.stringify(line_style_two)),
                name: '',
                data: []
			},
        ]
    }
    var team_chart = echarts.init(document.getElementById('team'));
	team_chart.setOption(team_option);


	// 区域终端数量
    let terminal_num_option = {
		...JSON.parse(JSON.stringify(option)),
		grid: {
			...JSON.parse(JSON.stringify(option.grid)),
			top: 15,
			left:90,
			bottom:15
		},
		xAxis:{
			...JSON.parse(JSON.stringify(option.xAxis)),
			type:'value',
			splitLine: {
                show:false,
			},
			axisLabel:{
				show:false,
			}
		},
		series:[
			{
				...JSON.parse(JSON.stringify(option.series[0])),
				label:{
					show: true,
					color: colors.label_color,
					position: 'right',
				},
				itemStyle: {
					color: colors.bar,
					barBorderRadius: [7, 7, 7, 7]
				},
			}
		],
		yAxis:{
			...JSON.parse(JSON.stringify(option.yAxis)),
			type:'category',
			data: [],
			axisLabel:{
				show:true,
				color:'#fff'
			}

		}
    }
    var terminal_num_chart = echarts.init(document.getElementById('terminal_num'));
	terminal_num_chart.setOption(terminal_num_option);
	
	// 终端拜访覆盖率
    let pushi_option = {
		...JSON.parse(JSON.stringify(option)),
		yAxis: {
			...JSON.parse(JSON.stringify(option.yAxis)),
			axisLabel:{
				...JSON.parse(JSON.stringify(option.yAxis.axisLabel)),
				formatter:function(value){
					return value+'%'
				}
			}
		},
		xAxis: {
			...JSON.parse(JSON.stringify(option.xAxis)),
			axisLabel:{
				...JSON.parse(JSON.stringify(option.xAxis.axisLabel)),
				interval:0,
			}
		},
		series:[
			{
				...JSON.parse(JSON.stringify(option.series[0])),
				label:{
					...JSON.parse(JSON.stringify(option.series[0].label)),
					formatter:function({data}){
						
						return `${data}%`
					}
				}
			}
		],
		grid: {
			...JSON.parse(JSON.stringify(option.grid)),
			top: 15,
		},
    }
    var pushi_chart = echarts.init(document.getElementById('pushi'));
	pushi_chart.setOption(pushi_option);
	
	return {
		goods:{
			goods_option,
			goods_chart,
		},
		region:{
			region_option,
			region_chart,
		},
		visit_num:{
			visit_num_option,
			visit_num_chart,
		},
		team:{
			team_option,
			team_chart,
		},
		terminal_num:{
			terminal_num_option,
			terminal_num_chart,
		},
		pushi:{
			pushi_option,
			pushi_chart,
		}
	}
}

export default {
	components:{
		Time
	},
    data() {
        return {
			// form:null,
			center:{
				lng: 115.86631,
				lat: 28.695285
			},
			visit_num_index:30,
			terminal_num_index:30,
			date_list:[
				{
					key:30,
					value:'近30天'
				},
				{
					key:7,
					value:'近7天'
				},
				{
					key:1,
					value:'近24时'
				}
			],
			visit_num:{},
			pushi:{},
			real:{
				attendance:{
					static_data:[],	
				},
				head:{},
				visit_list:[],
			}
        }
    },
    created() {
		this.$nextTick(()=>{
			let {goods,region,visit_num,team,terminal_num,pushi} = echarts_init();

			BiIndex().then(res=>{
				

				
				let {goods_sales,region_sales,region_customer,online_num} = res.data;

				// 商品销售额
				goods.goods_option.xAxis.data = goods_sales.xaxis
				goods.goods_option.series[0].data =  goods_sales.total;
				goods.goods_chart.setOption(goods.goods_option)

				// 区域销售情况
				region.region_option.xAxis.data = region_sales.xaxis
				region.region_option.series[0].data =  region_sales.total;
				region.region_option.series[1].data =  region_sales.num;
				region.region_chart.setOption(region.region_option)

				// 近30天在线人数
				team.team_option.xAxis.data = online_num.xaxis
				team.team_option.series[0].data =  online_num.online;
				team.team_option.series[1].data =  online_num.attendance;
				team.team_chart.setOption(team.team_option)

				// 区域终端数量
				terminal_num.terminal_num_option.yAxis.data = region_customer.xaxis
				terminal_num.terminal_num_option.series[0].data =  region_customer.num;
				terminal_num.terminal_num_chart.setOption(terminal_num.terminal_num_option)
			})

			this.visit_num = visit_num;
			this.pushi = pushi;

			
			this.visit_click({key:this.visit_num_index})
			this.terminal_click({key:this.terminal_num_index})

			this.times && clearTimeout(this.times);
			
			this.get_realtime();
			this.times = setInterval(()=>{
				this.get_realtime();
			},5000)

		})
	},
	beforeDestroy(){
		this.times && clearTimeout(this.times);
	},
    methods: {
		terminal_click(item){
			this.terminal_num_index = item.key;
			let pushi = this.pushi;
			visitCoverChart({
				data:{
					times:item.key
				}
			}).then(res=>{
				let data = res.data;
				pushi.pushi_option.xAxis.data = data.xaxis
				pushi.pushi_option.series[0].data =  data.ratio;
				pushi.pushi_chart.setOption(pushi.pushi_option)
			})
		},
		visit_click(item){
			this.visit_num_index = item.key
			let visit_num = this.visit_num;
			visitChart({
				data:{
					times:item.key
				}
			}).then(res=>{
				let data = res.data;
				visit_num.visit_num_option.xAxis.data = data.xaxis
				visit_num.visit_num_option.series[0].data =  data.num;
				visit_num.visit_num_chart.setOption(visit_num.visit_num_option)
			})
		},
		get_realtime(){
			realtime().then(res=>{
				this.real = res.data;
			})
		},
		get_map_location(res_data,data = [], page = 0){
			location({
				data:{
					page:++page
				}
			}).then(res=>{
				data = [...data,...res.data.data];
				this.show_map_dots(res_data,data);
				if(res.data.data.length ){
					this.get_map_location(res_data,data,page);
				}
			})
		},
		show_map_dots(res,data){
			let {BMap,map,mapv} = res;

			let list = [];

			data = data.map((item) => {
				return {
					geometry: {
						type: 'Point',
						coordinates: [item.longitude, item.latitude]
					}
				}
			});

			var dataSet = new mapv.DataSet(data);
			var options = {
				fillStyle: '#F00',
				shadowBlur: 10,
				globalCompositeOperation: 'lighter',
				size: 2,
				draw: 'simple'
			}
			var mapvLayer = new mapv.baiduMapLayer(map, dataSet, options);
		},
		readyMap({BMap, map}){
			var mapv = require("mapv")

			map.centerAndZoom(new BMap.Point(105.403119, 38.028658), 5); 
			map.enableScrollWheelZoom(true);
			map.setMapStyle({
				style: 'midnight'
			});

			this.get_map_location({
				BMap,
				map,
				mapv
			})

			// var randomCount = 1000;

			// var data = [];

			// var citys = ["北京","天津","上海","重庆","石家庄","太原","呼和浩特","哈尔滨","长春","沈阳","济南","南京","合肥","杭州","南昌","福州","郑州","武汉","长沙","广州","南宁","西安","银川","兰州","西宁","乌鲁木齐","成都","贵阳","昆明","拉萨","海口"];

			// while (randomCount--) {
			// 	var cityCenter = mapv.utilCityCenter.getCenterByCityName(citys[parseInt(Math.random() * citys.length)]);
			// 	data.push({
			// 		geometry: {
			// 			type: 'Point',
			// 			coordinates: [cityCenter.lng - 2 + Math.random() * 4, cityCenter.lat - 2 + Math.random() * 4]
			// 		},
			// 		count: 30 * Math.random()
			// 	});
			// }

			
		}
    }
}
</script>

<style lang="less">
@import url("../../assets/bi/less/bi.less");
</style>